import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { GetInContact } from "../components/getInContact";

const DatenschutzPage = ({ data }) => (
  <Layout>
    <Seo title="Datenschutz" />
    <div className="content-wrapper content-wrapper--text">
      <div className="content pure-g">
        <div className="l-box pure-u-1">
          <div dangerouslySetInnerHTML={{ __html: data.iubendaDocument.privacyPolicy.content }} />
        </div>
      </div>
    </div>
    <GetInContact />
  </Layout>
);

export default DatenschutzPage;

export const query = graphql`
  query {
		iubendaDocument {
		  privacyPolicy {
        title,
        content
		  }
    }
  }
`;